import React from 'react'
import ComingSoon from '../../components/ComingSoon'

function AsistenteIA() {
  return (
    <>
      <ComingSoon />
    </>
  )
}

export default AsistenteIA